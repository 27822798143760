import styled from 'styled-components';
import PropTypes from 'prop-types';

const Title = styled.h1(
  ({ size, weight, color, uppercase, marginBottom, marginTop }) => `
    color: ${color};
    font-size: ${size}px;
    font-weight: ${weight};
    margin: 0;
    margin-bottom: ${marginBottom}px;
    margin-top: ${marginTop}px;

    ${uppercase ? 'text-transform: uppercase;' : ''}
  `
);

Title.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  weight: PropTypes.number,
  uppercase: PropTypes.bool,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
};

Title.defaultProps = {
  color: 'inherit',
  size: 48,
  weight: 400,
  uppercase: false,
  marginBottom: 0,
  marginTop: 0,
};

export default Title;

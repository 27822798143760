import { createGlobalStyle } from 'styled-components';

import FuturaRegular from '../../fonts/futura.ttf';
import FuturaMedium from '../../fonts/futura-medium.ttf';
import FuturaBold from '../../fonts/futura-bold.ttf';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: "Futura";
    font-weight: 400;
    src: ${FuturaRegular};
  }

  @font-face {
    font-family: "Futura";
    font-weight: 500;
    src: ${FuturaMedium};
  }

  @font-face {
    font-family: "Futura";
    font-weight: 700;
    src: ${FuturaBold};
  }
`;

export default Fonts;

import styled from 'styled-components';

const Ordered = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 27px;
`;

const Item = styled.li(
  ({ numbered }) => `
  font-size: 16px;
  color: #423131;

  ${
    numbered
      ? `
    display: flex;
    margin-bottom: 30px;
  `
      : ''
  }
`
);

const Unordered = styled.ul(
  ({ bullet }) => `
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 29px;

    ${
      bullet
        ? `
      padding-left: 20px;
      ${Item} {
        :before {
          content: '${bullet}';
          margin-left: -20px;
          margin-right: 10px;
        }
      }  
    `
        : ''
    }
  `
);

const Number = styled.span`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f6f6f6;
  margin-right: 10px;
  width: 28px;
  height: 28px;
  font-size: 14px;
  font-weight: bold;
`;

export { Unordered, Item, Ordered, Number };

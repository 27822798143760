import styled from 'styled-components';

const Tag = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #acacac;
  padding: 5px 10px;
  border-radius: 6px;
  background-color: transparent;
  border: 1px solid #e4e4e4;

  @media (max-width: 530px) {
    margin-top: 10px;
  }
`;

export default Tag;

import React from 'react';
import styled from 'styled-components';

import { Title, Text } from '..';

const Wrapper = styled.div`
  background-color: #f6f6f6;
  margin-bottom: 10px;
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${Text} {
    line-height: 24px;
  }
`;

const Emoji = styled.img`
  margin-right: 24px;
`;

const Process = ({ emoji, title, description }) => (
  <Wrapper>
    <Emoji src={emoji} alt="emoji" />
    <div>
      <Title as="h3" size={16} weight={500} marginBottom={10}>
        {title}
      </Title>
      <Text size={16} weight={400}>
        {description}
      </Text>
    </div>
  </Wrapper>
);

export default Process;

import styled from 'styled-components';

const Divider = styled.hr`
  background-color: #e2e2e2;
  height: 1px;
  border: none;
  margin-top: 50px;
`;

export default Divider;

import styled from 'styled-components';
import PropTypes from 'prop-types';

const Text = styled.p(
  ({ size, weight, color, uppercase, lineHeight, marginBottom }) => `
    font-size: ${size}px;
    font-weight: ${weight};
    color: ${color};
    margin: 0;
    line-height: ${lineHeight};
    margin-bottom: ${marginBottom}px;

    ${
      uppercase
        ? `
      text-transform: uppercase;
    `
        : ''
    }
  `
);

Text.propTypes = {
  size: PropTypes.number,
  weight: PropTypes.number,
  color: PropTypes.string,
  uppercase: PropTypes.bool,
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.number,
};

Text.defaultProps = {
  size: 20,
  weight: 400,
  color: '#888',
  uppercase: false,
  lineHeight: 'initial',
  marginBottom: 0,
};

export default Text;

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

const Link = styled(GatsbyLink)(
  ({ size, weight }) => `
    text-decoration: none;
    font-size: ${size}px;
    font-weight: ${weight};
    color: #2f2f2f;
    transition: all 0.2s;

    &:hover {
      text-decoration: underline;
      color: #e39b7f;
    }
  `
);

Link.propTypes = {
  to: PropTypes.string,
  size: PropTypes.number,
  weight: PropTypes.number,
};

Link.defaultProps = {
  to: undefined,
  size: 14,
  weight: 500,
};

export default Link;

import React from 'react';
import styled from 'styled-components';

import cafe from '../../images/cafe.png';
import { Text } from '..';

const Wrapper = styled.footer`
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;

  ${Text} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;

    img {
      margin-left: 5px;
    }
  }
`;

const Social = styled.div`
  color: #e39b7f;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 80px;
`;

const SocialLink = styled.a`
  color: #e39b7f;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => (
  <Wrapper>
    <Text size={24} weight={500} color="#423131">
      Bora falar mais?
    </Text>
    <Text size={16} weight={400} color="#9B9B9B">
      <span>Sobre projetos ou sobre a vida</span>{' '}
      <img alt="Emoji de café" src={cafe} />
    </Text>
    <Social>
      <SocialLink href="mailto:bia.barbf@gmail.com">Email</SocialLink> ·{' '}
      <SocialLink
        href="https://www.linkedin.com/in/beatrizbfigueiredo/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Linkedin
      </SocialLink>
    </Social>
    <Text size={16} weight={400} color="#888888">
      Desenhado por Beatriz Figueiredo 2019
    </Text>
  </Wrapper>
);

export default Footer;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from '../Link';

const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 84px;
  padding-top: 60px;

  @media (max-width: 900px) {
    padding-top: 30px;
    padding-bottom: 40px;
  }

  > ${Link} {
    text-decoration: none;
  }
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 900;
  color: #000;
  margin: 0;
`;

const Menu = styled.nav`
  a {
    margin-right: 20px;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Header = ({ siteTitle }) => (
  <Wrapper>
    <Link to="/">
      <Title>BiaFig</Title>
    </Link>
    <Menu>
      <Link to="/404">Curriculum</Link>
      <Link to="/sobre-mim" activeStyle={{ textDecoration: 'underline' }}>
        Sobre mim
      </Link>
    </Menu>
  </Wrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;

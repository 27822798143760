import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Title, Text, Link, Tag } from '..';

const Wrapper = styled.article(
  ({ inProgress }) => `
    flex-basis: 556px;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 50px;
    padding: 0 24px;

    @media (max-width: 900px) {
      padding: 0;
    }

    ${Title} {
      display: inline-block;
      transition: all 0.2s;
      border-bottom: 1px solid transparent;
    }

    ${Link} {
      text-decoration: none;
    }

    img {
      transition: all 0.2s;
    }

    &:hover {
      ${Title} {
        color: #e39b7f;
        border-color: #e39b7f;
      }

      img {
        transform: scale(1.05);
      }
    }
  `
);

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  @media (max-width: 530px) {
    display: block;
  }
`;

const Picture = styled.div(
  ({ align }) => `
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    min-height: 359px;
    align-items: ${align};
    overflow: hidden;
  `
);

const Image = styled.img(
  ({ imageWidth }) => `
    max-width: 100%;
    width: ${imageWidth}px;
  `
);

const Job = ({
  image,
  title,
  description,
  align,
  inProgress,
  href,
  imageWidth,
}) => (
  <Wrapper inProgress={inProgress}>
    <Link to={href}>
      <Picture align={align}>
        <Image src={image} imageWidth={imageWidth} />
      </Picture>
      <TitleWrapper>
        <div>
          <Title
            as="h3"
            size={16}
            marginBottom={5}
            weight={500}
            color="#423131"
          >
            {title}
          </Title>
          <Text size={16} weight={400} color="#ACACAC">
            {description}
          </Text>
        </div>
        {inProgress && <Tag>conteúdo em construção</Tag>}
      </TitleWrapper>
    </Link>
  </Wrapper>
);

Job.propTypes = {
  image: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['flex-end', 'center']),
  inProgress: PropTypes.bool,
  href: PropTypes.string,
};

Job.defaultProps = {
  align: 'flex-end',
  inProgress: false,
  href: '/#',
};

export default Job;

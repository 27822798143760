import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Title, Text } from '..';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Title} {
    text-align: center;
    margin-bottom: 20px;
  }

  ${Text} {
    text-align: center;
  }
`;

const Image = styled.img`
  margin-top: 74px;
  max-width: 100%;
`;

const Highlight = ({ title, description, image }) => (
  <Wrapper>
    <Title as="h2" size={34} weight={500} color="#423131">
      {title}
    </Title>
    <Text size={28} weight={400} color="#ACACAC">
      {description}
    </Text>
    <Image src={image} />
  </Wrapper>
);

Highlight.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.node.isRequired,
};

Highlight.defaultProps = {
  description: undefined,
};

export default Highlight;

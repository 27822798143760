import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import Fonts from './Fonts';

const Reset = createGlobalStyle(
  ({ backgroundColor }) => `
    html, body {
      padding: 0;
      margin: 0;
      color: #2f2f2f;
      background-color: ${backgroundColor};
      overflow-x: hidden;

      font-family: 'Futura';
    }

    * {
      box-sizing: border-box;
    }

    strong {
      color: #80CDBA;
      font-weight: 500;
    }
  `
);

const GlobalStyle = ({ backgroundColor }) => (
  <>
    <Fonts />
    <Reset backgroundColor={backgroundColor} />
  </>
);

GlobalStyle.propTypes = {
  backgroundColor: PropTypes.string,
};

GlobalStyle.defaultProps = {
  backgroundColor: '#fff',
};

export default GlobalStyle;

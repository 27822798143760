import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div(
  ({ backgroundColor, fluid, marginBottom }) => `
    background-color: ${backgroundColor};
    max-width: ${fluid ? '100%' : '1200px'};
    margin: 0 auto;
    margin-bottom: ${marginBottom}px;
    ${fluid ? '' : 'padding: 0 20px;'}
    position: relative;
  `
);

Container.propTypes = {
  backgroundColor: PropTypes.string,
  fluid: PropTypes.bool,
  marginBottom: PropTypes.number,
};

Container.defaultProps = {
  backgroundColor: 'unset',
  fluid: false,
  marginBottom: 0,
};

export default Container;

import React from 'react';
import PropTypes from 'prop-types';

import { Header, GlobalStyle, Footer, Container, Divider } from '..';

const Layout = ({ children }) => (
  <>
    <Container>
      <GlobalStyle />
      <Header />
      <main>{children}</main>
    </Container>
    <Divider />
    <Container>
      <Footer />
    </Container>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
